(function ($) {

	"use strict";

	$(window).on('load', function () {
		$('[data-loader="circle-side"]').fadeOut(); // will first fade out the loading animation
		$('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website.
		$('body').delay(350);
		$('.hero_in h1,.hero_in form').addClass('animated');
		$('.hero_single, .hero_in').addClass('start_bg_zoom');
		$(window).scroll();
	});

	// Sticky nav
	$(window).on('scroll', function () {
		if ($(this).scrollTop() > 1) {
			$('.header').addClass("sticky");
		} else {
			$('.header').removeClass("sticky");
		}
	});

	// Sticky sidebar
	$('#sidebar').theiaStickySidebar({
		additionalMarginTop: 150
	});

	// Sticky titles
	$('.fixed_title').theiaStickySidebar({
		additionalMarginTop: 180
	});

	$(document).ready(function() {
		var $menu = $('#mobile-menu');
		var $hamburger = $("#hamburger");
		var $closeIcon = $("#mobile-menu .icon_close")

		$hamburger.on('click', () => $menu.toggleClass('visible'));
		$closeIcon.on('click', () => $menu.toggleClass('visible'));
	});
	// Mobile Mmenu
	// $(document).ready(function() {
	// 	var $menu = $("nav#menu").mmenu({
	// 		"extensions": ["pagedim-black"],
	// 		counters: true,
	// 		keyboardNavigation: {
	// 			enable: true,
	// 			enhance: true
	// 		},
	// 		navbar: {
	// 			title: 'MENU'
	// 		},
	// 		navbars: [{position:'bottom',content: ['<a href="#0">© 2020 Panagea</a>']}]},
	// 		{
	// 		// configuration
	// 		clone: true,
	// 		classNames: {
	// 			fixedElements: {
	// 				fixed: "menu_fixed",
	// 				sticky: "sticky"
	// 			}
	// 		}
	// 	});
	// 	var API = $menu.data("mmenu");
	// 	$icon.on("click", function () {
	// 		API.open();
	// 	});
	// 	API.bind("open:finish", function () {
	// 		setTimeout(function () {
	// 			$icon.addClass("is-active");
	// 		}, 100);
	// 	});
	// 	API.bind("close:finish", function () {
	// 		setTimeout(function () {
	// 			$icon.removeClass("is-active");
	// 		}, 100);
	// 	});
	// });

	// WoW - animation on scroll
	var wow = new WOW(
	  {
		boxClass:     'wow',      // animated element css class (default is wow)
		animateClass: 'animated', // animation css class (default is animated)
		offset:       0,          // distance to the element when triggering the animation (default is 0)
		mobile:       true,       // trigger animations on mobile devices (default is true)
		live:         true,       // act on asynchronously loaded content (default is true)
		callback:     function(box) {
		  // the callback is fired every time an animation is started
		  // the argument that is passed in is the DOM node being animated
		},
		scrollContainer: null // optional scroll container selector, otherwise use window
	  }
	);
	wow.init();

	// Header button explore
    $('a[href^="#"].btn_explore').on('click', function (e) {
			e.preventDefault();
			var target = this.hash;
			var $target = $(target);
			$('html, body').stop().animate({
				'scrollTop': $target.offset().top
			}, 800, 'swing', function () {
				window.location.hash = target;
			});
		});

	//  Video popups
	$('.video').magnificPopup({type:'iframe'});	/* video modal*/

	// Image popups
	$('.magnific-gallery').each(function () {
		$(this).magnificPopup({
			delegate: 'a',
			type: 'image',
            preloader: true,
			gallery: {
				enabled: true
			},
			removalDelay: 500, //delay removal by X to allow out-animation
			callbacks: {
				beforeOpen: function () {
					// just a hack that adds mfp-anim class to markup
					this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
					this.st.mainClass = this.st.el.attr('data-effect');
				}
			},
			closeOnContentClick: true,
			midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
		});
	});

	// Modal Sign In
	// $('#sign-in').magnificPopup({
	// 	type: 'inline',
	// 	fixedContentPos: true,
	// 	fixedBgPos: true,
	// 	overflowY: 'auto',
	// 	closeBtnInside: true,
	// 	preloader: false,
	// 	midClick: true,
	// 	removalDelay: 300,
	// 	closeMarkup: '<button title="%title%" type="button" class="mfp-close"></button>',
	// 	mainClass: 'my-mfp-zoom-in'
	// });

	// Modal generic
	// $('#modal').magnificPopup({
	// 	type: 'inline',
	// 	fixedContentPos: true,
	// 	fixedBgPos: true,
	// 	overflowY: 'auto',
	// 	closeBtnInside: true,
	// 	preloader: false,
	// 	midClick: true,
	// 	removalDelay: 300,
	// 	closeMarkup: '<button title="%title%" type="button" class="mfp-close"></button>',
	// 	mainClass: 'my-mfp-zoom-in'
	// });

	// Show Password
	$('#password').hidePassword('focus', {
		toggle: {
			className: 'my-toggle'
		}
	});

	// Forgot Password
	$("#forgot").click(function () {
		$("#forgot_pw").fadeToggle("fast");
	});

	// Accordion
	function toggleChevron(e) {
		$(e.target)
			.prev('.card-header')
			.find("i.indicator")
			.toggleClass('ti-minus ti-plus');
	}
	$('.accordion_2').on('hidden.bs.collapse shown.bs.collapse', toggleChevron);
		function toggleIcon(e) {
        $(e.target)
            .prev('.panel-heading')
            .find(".indicator")
            .toggleClass('ti-minus ti-plus');
    }

	// Jquery select
	$('.custom-search-input-2 select, .custom-select-form select').niceSelect();

	// Atltenative checkbox styles - Switchery
	var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
	elems.forEach(function (html) {
		var switchery = new Switchery(html, {
			size: 'small'
		});
	});

	// Like Icon
    $('.wish_bt').on('click', function(e){
    	e.preventDefault();
		$(this).toggleClass('liked');
	});

	// Collapse filters
	$(window).bind('load resize', function () {
		var width = $(window).width();
		if ($(this).width() < 991) {
			$('.collapse#collapseFilters').removeClass('show');
		} else {
			$('.collapse#collapseFilters').addClass('show');
		};
	});

	//Scroll to top
	$(window).on('scroll', function () {
		'use strict';
		if ($(this).scrollTop() != 0) {
			$('#toTop').fadeIn();
		} else {
			$('#toTop').fadeOut();
		}
	});
	$('#toTop').on('click', function () {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
	});

	// Carousels
	$('#carousel').owlCarousel({
		center: true,
		items: 2,
		loop: true,
		margin: 10,
		responsive: {
			0: {
				items: 1,
				dots:false
			},
			600: {
				items: 2
			},
			1000: {
				items: 4
			}
		}
	});
	$('#recommended').owlCarousel({
		center: true,
		items: 2,
		loop: true,
		margin: 0,
		responsive: {
			0: {
				items: 1
			},
			767: {
				items: 2
			},
			1000: {
				items: 3
			},
			1400: {
				items: 4
			}
		}
	});

	$('#recommended_adventure').owlCarousel({
		center: false,
		items: 2,
		loop: false,
		margin: 15,
		responsive: {
			0: {
				items: 1
			},
			767: {
				items: 3
			},
			1000: {
				items: 4
			},
			1400: {
				items: 5
			}
		}
	});

	// Sticky filters
	$(window).bind('load resize', function () {
		var width = $(window).width();
		if (width <= 991) {
			$('.sticky_horizontal').stick_in_parent({
				bottoming:false,
				offset_top: 50
			});
		} else {
			$('.sticky_horizontal').stick_in_parent({
				bottoming:false,
				offset_top: 67
			});
		}
	});

	// Opacity mask
	$('.opacity-mask').each(function(){
		$(this).css('background-color', $(this).attr('data-opacity-mask'));
	});

	// Aside panel
	$(".aside-panel-bt").on("click", function () {
		$("#panel_dates").toggleClass("show")
		$(".layer").toggleClass("layer-is-visible")
	});

	// Show more button
	$(".content_more").hide();
    $(".show_hide").on("click", function () {
        var txt = $(".content_more").is(':visible') ? 'Read More' : 'Read Less';
        $(this).text(txt);
        $(this).prev('.content_more').slideToggle(200);
    });

	// Secondary nav scroll
	var $sticky_nav= $('.secondary_nav');
	$sticky_nav.find('a').on('click', function(e) {
		e.preventDefault();
		var target = this.hash;
		var $target = $(target);
		$('html, body').animate({
			'scrollTop': $target.offset().top - 140
		}, 800, 'swing');
	});
	$sticky_nav.find('ul li a').on('click', function () {
		$sticky_nav.find('ul li a.active').removeClass('active');
		$(this).addClass('active');
	});

	// Faq section
	$('#faq_box a[href^="#"]').on('click', function () {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
			|| location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			   if (target.length) {
				 $('html,body').animate({
					 scrollTop: target.offset().top -185
				}, 800);
				return false;
			}
		}
	});
	$('ul#cat_nav li a').on('click', function () {
		$('ul#cat_nav li a.active').removeClass('active');
		$(this).addClass('active');
	});

	// Button show/hide map
	$(".btn_map, .btn_map_in").on("click", function () {
		var el = $(this);
		el.text() == el.data("text-swap") ? el.text(el.data("text-original")) : el.text(el.data("text-swap"));
		$('html, body').animate({
			scrollTop: $("body").offset().top +385
		}, 600);
	});

	// Panel Dropdown
    function close_panel_dropdown() {
		$('.panel-dropdown').removeClass("active");
    }
    $('.panel-dropdown a').on('click', function(e) {
		if ( $(this).parent().is(".active") ) {
            close_panel_dropdown();
        } else {
            close_panel_dropdown();
            $(this).parent().addClass('active');
        }
        e.preventDefault();
    });

    // Closes dropdown on click outside the conatainer
	var mouse_is_inside = false;

	$('.panel-dropdown').hover(function(){
	    mouse_is_inside=true;
	}, function(){
	    mouse_is_inside=false;
	});

	$("body").mouseup(function(){
	    if(! mouse_is_inside) close_panel_dropdown();
	});

	/* Dropdown user logged */
	$('.dropdown-user').hover(function () {
		$(this).find('.dropdown-menu').stop(true, true).delay(50).fadeIn(300);
	}, function () {
		$(this).find('.dropdown-menu').stop(true, true).delay(50).fadeOut(300);
	});

	// Search half screen map
	$('a.search_map').on('click',function () {
		$('.search_map_wp').slideToggle("fast");
	});

	// Range slider half screen map
	$('input[type="range"]').rangeslider({
		polyfill: false,
		onInit: function () {
			this.output = $(".distance span").html(this.$element.val());
		},
		onSlide: function (
			position, value) {
			this.output.html(value);
		}
	});

	// Range DatePicker scroll fix
	$(function () {
	    $(window).bind("resize", function () {
	        if ($(this).width() < 768) {
	            $('.input-dates').removeClass('scroll-fix')
	        } else {
	            $('.input-dates').addClass('scroll-fix')
	        }
	    }).trigger('resize');
	});

})(window.jQuery);

